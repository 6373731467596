<template>
  <v-dialog :value="dialog" max-width="750px" :persistent="true">
    <v-card>
      <v-card-title class="headline">
        ¿Estás seguro que quieres eliminar/dar de baja este elemento?
      </v-card-title>
      <v-card-text>
        <v-row align="center" justify="center">
          <v-card>
            <v-card-title class="subheading font-weight-bold">
              Número de cuenta: {{ account_number }}
            </v-card-title>

            <v-divider></v-divider>

            <v-list dense>
              <v-list-item>
                <v-list-item-content>Días inactiva:</v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ REMAINING_DAYS }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>Radio SN:</v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ SN }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>Fecha de caducidad:</v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ final_date }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDeleteDialog"
          >Cancel</v-btn
        >
        <v-btn
          color="blue darken-1"
          text
          @click="deleteService"
          >OK</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    account_number: String,
    REMAINING_DAYS: Number,
    SN: String,
    final_date: String,
    closeDeleteDialog: Function,
    deleteService: Function
  },
};
</script>