<template>
  <v-dialog persistent :value="dialog" max-width="500">
    <v-card>
      <v-card-title>
        <span class="headline">Reactivar cuenta</span>
      </v-card-title>
      <v-card-text>
        <v-row justify="center">
          <v-date-picker
            elevation="15"
            :value="dateStart"
            v-model="picker"
            @change="setDatePicker(picker)"
          />
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="closeDialog"
        >
          Close
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="updateAccountRoutine"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    data() {
        return {
            rules: {
             name: [val => (val || '').length > 0 || 'This field is required']
            },
            picker: null
        }
    },
    computed:{
      ...mapState('reactivateAccount',['dialog','dateStart']),
      ...mapState('notifications', ['notificationsCount'])
    },
    methods: {
      ...mapMutations('reactivateAccount', ['closeDialog','showDialog','setDatePicker']),
      async updateAccountRoutine(){
        await this.$store.dispatch('reactivateAccount/setUpdatedDate');
        await this.$store.dispatch('notifications/getCount');
        await this.$store.dispatch('notifications/getAllNotifications');
      }
    }
}
</script>