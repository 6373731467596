<template>
  <div id="app">
    <Navbar />
    <v-app id="create">
    <v-container class="justify-center">
      <v-card>
        <v-list>
          <v-list-item-group>
            <v-list-item-content
              v-for="(item, index) in allNotifications"
              :key="index"
            >
              <div class="justify-center pl-2">
                <v-list-item-title>Radio SN: {{ item.SN }}</v-list-item-title>
                <v-list-item-subtitle class="item.headline"
                  >Cuenta: {{ item.account_number }}</v-list-item-subtitle
                >
                <div class="float-rigth">
                  <v-list-item-action-text
                    >{{ item.REMAINING_DAYS }}d</v-list-item-action-text
                  >
                  <v-icon :color="getColor(item.REMAINING_DAYS)"
                    >mdi-alert</v-icon
                  >
                </div>
              </div>
              <v-btn
                elevation="1"
                rounded
                small
                text
                @click="showDialog(item.account_id)"
                >Reactivar</v-btn
              >
              <v-btn
                elevation="1"
                rounded
                small
                color="red"
                text
                @click="
                  showDialogDelete({
                    account_id: item.account_id,
                    account_number: item.account_number,
                    REMAINING_DAYS: item.REMAINING_DAYS,
                    SN: item.SN,
                    final_date: item.final_date,
                  })
                "
                >Baja</v-btn
              >
            </v-list-item-content>
          </v-list-item-group>
        </v-list>
      </v-card>
        </v-container>
      <ReactivateAccount />
      <DeleteAccount
        :dialog="dialog"
        :account_number="account_number"
        :REMAINING_DAYS="REMAINING_DAYS"
        :SN="SN"
        :final_date="final_date"
        :closeDeleteDialog="closeDialogDelete"
        :deleteService="deleteAccountF"
      />
    </v-app>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import ReactivateAccount from "../components/Dialogs/AccountsDialogs/reactivateAccount";
import DeleteAccount from "../components/Dialogs/AccountsDialogs/deleteAccount";
import Navbar from "../components/Navbar";

export default {
  components: {
    ReactivateAccount,
    DeleteAccount,
    Navbar,
  },
  computed: {
    ...mapState("notifications", ["allNotifications"]),
    ...mapState("deleteAccount", [
      "dialog",
      "account_number",
      "REMAINING_DAYS",
      "SN",
      "final_date",
      "account_id",
    ]),
  },
  created() {
    this.$store.dispatch("notifications/getAllNotifications");
  },
  methods: {
    ...mapMutations("reactivateAccount", ["showDialog"]),
    ...mapMutations("deleteAccount", ["showDialogDelete", "closeDialogDelete"]),
    deleteAccountF() {
      this.$store.dispatch("deleteAccount/deleteAccount");
    },
    getColor(tiempo) {
      if (tiempo <= 30 && tiempo > 0) {
        return "yellow darken-1";
      } else {
        return "red lighten-1";
      }
    },
  },
};
</script>